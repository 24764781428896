/* line 5, ../../private/sass/layout/_common.scss */
.form-default.profile-area .value .item .toggle-dropdown {
  min-height: 35px;
}
/* line 12, ../../private/sass/layout/_common.scss */
.form-default .row select {
  width: 237px;
  height: 35px;
}

/* line 21, ../../private/sass/layout/_common.scss */
.cave-area .select.date .value .item {
  width: 27%;
}

/* line 27, ../../private/sass/layout/_common.scss */
ul.dropdownable {
  max-height: 200px;
  overflow-y: scroll;
}

/* line 31, ../../private/sass/layout/_common.scss */
/*.newsletter div.image {
  background-color: grey;
  height: 62px;
}*/

/* line 35, ../../private/sass/layout/_common.scss */
.display-none {
  display: none;
}

/* line 38, ../../private/sass/layout/_common.scss */
td.price {
  white-space: nowrap;
}

/* line 1, ../../private/sass/layout/_popup.scss */
.overlay-box {
  display: none;
}
/* line 3, ../../private/sass/layout/_popup.scss */
.overlay-box.active {
  display: block;
}
/* line 6, ../../private/sass/layout/_popup.scss */
.overlay-box .two-box-wrapper {
  width: 640px;
  overflow: auto;
}
/* line 9, ../../private/sass/layout/_popup.scss */
.overlay-box .two-box-wrapper > div.box {
  width: 280px;
  float: left;
}
/* line 12, ../../private/sass/layout/_popup.scss */
.overlay-box .two-box-wrapper > div.box + div.box {
  margin-left: 20px;
}

/* line 4, ../../private/sass/layout/_abonnement.scss */
#abonnement-page .tab-content .panels-wrapper .panel {
  width: 50%;
  float: left;
}
/* line 15, ../../private/sass/layout/_abonnement.scss */
#abonnement-page #avantages-details {
  display: none;
}
/* line 20, ../../private/sass/layout/_abonnement.scss */
#abonnement-page .price-box span {
  margin-left: 20px;
}
/* line 22, ../../private/sass/layout/_abonnement.scss */
#abonnement-page .price-box span.millesime-price.cross {
  background-image: url("../../public/img/croix.png");
  background-position: -27px -11px;
  background-repeat: no-repeat;
}
/* line 32, ../../private/sass/layout/_abonnement.scss */
#abonnement-page .panels-wrapper .offre span {
  display: none;
}
/* line 35, ../../private/sass/layout/_abonnement.scss */
#abonnement-page .panels-wrapper .offre span.active {
  display: block;
}
/* line 40, ../../private/sass/layout/_abonnement.scss */
#abonnement-page .hide {
  display: none;
}

.search-sidebar .tags .item::after {
    content: none;
}
.search-sidebar .tags .item {
    padding: 5px 8px 5px 5px;
}

#sonata_user_profile_form_plainPassword > div.form-row {
    margin-bottom: 11px;
}

label.no-placeholder {
    padding: 0;
    position: relative;
}
textarea[required] + label.no-placeholder, input[required] + label.no-placeholder, textarea[required] + label.no-placeholder, input[required] + label.no-placeholder {
    padding: 0;
    position: relative;
}
textarea[required] + label.no-placeholder::after, input[required] + label.no-placeholder::after {
    content: '';
}

/*ajout 15-05-2015 */
.toggle-dropdown .text {
    font-size: 0.9em !important;
    font-weight: normal!important;
}

.connection {
    margin-left: 164px;
}

.pagination .active span {
    color: #252424;
    font-size: 1.2em;
    font-weight: bold;
    padding: 10px 5px;
}
.pagination .disabled span {
    color: #d0d0d0;
    font-size: 1.2em;
    font-weight: bold;
}

/* Styles CSS Mentions légales */
    .ml-content .main-title {
        margin-bottom: 15px;
    }
    .ml-content li, .ml-content p {
        text-align: justify;
    }
    .ml-content li {
        list-style-type: none;
    }
    .ml-content li a:hover {
        text-decoration: underline;
    }
    .ml-content p a {
        color: #d33b38;
    }
    .ml-content td a {
        color: #d33b38;
    }
    .ml-content p a:hover {
        text-decoration: underline;
    }
    .ml-content .contactBloc p {
        line-height: 1.5em;
        margin: 0;
    }
    .ml-content ul.list {
        margin: 0 0 0 1.5em;
        padding: 0;
    }
    .ml-content li .address {
        float: right;
        overflow: hidden;
    }
    .ml-content ul.sommaire {
        color: #D33B38;
    }
    .ml-content p.space {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 0.5em;
    }
/* /Styles CSS Mentions légales */

.form-default .radio label {
    margin-right: 14px;
}

@media screen and (max-width: 480px) {
    .detail-area2.detail-area2-map .info .value.coordonnees-region {
        margin-top: 15px; } }

